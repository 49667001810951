.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 10px;
    width: 100%;
    height: 100vh;
    grid-template-areas:
        "text1 photo1"
        "photo2 text2";
}

.text1,
.text2 {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photo1,
.photo2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.photo1 img,
.photo2 img {
    width: 40vw;
    /* Adjust this value to scale down the image size */
    height: auto;
}

/* Add this to your App.css */
.fixed-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    /* Ensure the navbar stays on top of other elements */
}