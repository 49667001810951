.image-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.image-container::before {
    content: "";
    display: block;
    padding-top: 100%;
    /* for square images */
}

.fixed-size-image {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 0;
    width: 80%;
    height: 80%;
    object-fit: cover;
}